import * as React from "react";

import LayoutComponent from "../components/layout";
import PiecesForm from "../components/forms/piecesform";

// markup
const Pieces = () => {
  return (
    <LayoutComponent>
      <PiecesForm />
    </LayoutComponent>
  );
};

export default Pieces;
